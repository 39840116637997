import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormSubmit from "components/forms/FormSubmit";
import InputText from "components/forms/InputText";
import InputTextarea from "components/forms/InputTextarea";
import FormSuccess from "components/forms/FormSuccess";
import FormError from "components/forms/FormError";
import FormLegals from "components/forms/FormLegals";

import { schema, resolver } from "data/form-schemas/consumer-complaints.js";
import { sendContactForm } from "services/index.js";

// import "./ContactForm.scss";

export default function ConsumerComplaints({
  emailTo = "consumerComplaints@loanpal.com",
  emailToCopy = false,
}) {
  const [formStatus, setFormStatus] = useState("WAIT");
  const [submited, setSubmited] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(resolver),
  });

  const onSubmit = async ({
    name,
    phone,
    emailFrom,
    address,
    complaintInvolves,
    statementOfProblem,
    desiredResolution,
  }) => {
    setSubmited(true);

    const formData = {
      emailTo: emailTo,
      emailFrom: emailFrom,
      subject: `Loanpal consumer complaint from ${name}`,
      name: name,
      phone: phone,
      address: address,
      complaintInvolves: complaintInvolves,
      statementOfProblem: statementOfProblem,
      desiredResolution: desiredResolution,
    };

    try {
      const response = await sendContactForm(formData);
      if (!emailToCopy) {
        setFormStatus("SENT");
      }
    } catch (err) {
      setFormStatus("ERROR");
    }

    if (emailToCopy) {
      try {
        const response = await sendContactForm({
          ...formData,
          emailTo: emailToCopy,
        });
        setFormStatus("SENT");
      } catch (err) {
        setFormStatus("ERROR");
      }
    }

    setSubmited(false);
  };

  return (
    <div className="form-do-not-sell">
      {formStatus === "SENT" && <FormSuccess type="do-not-sell" />}

      {formStatus === "ERROR" && <FormError emailTo={emailTo} />}

      {formStatus === "WAIT" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Please complete the form below.</h2>
          <p className="disclaimer">
            Required fields <span>*</span>
          </p>

          <InputText
            {...schema["name"]}
            register={register}
            errors={errors["name"]}
            value={watch("name")}
          />
          <InputText
            {...schema["phone"]}
            register={register}
            errors={errors["phone"]}
            value={watch("phone")}
          />
          <InputText
            {...schema["emailFrom"]}
            register={register}
            errors={errors["emailFrom"]}
            value={watch("emailFrom")}
          />
          <InputText
            {...schema["address"]}
            register={register}
            errors={errors["address"]}
            value={watch("address")}
          />

          <InputTextarea
            {...schema["complaintInvolves"]}
            register={register}
            errors={errors["complaintInvolves"]}
            value={watch("complaintInvolves")}
          />
          <InputTextarea
            {...schema["statementOfProblem"]}
            register={register}
            errors={errors["statementOfProblem"]}
            value={watch("statementOfProblem")}
          />
          <InputTextarea
            {...schema["desiredResolution"]}
            register={register}
            errors={errors["desiredResolution"]}
            value={watch("desiredResolution")}
          />

          <FormLegals />

          <FormSubmit data-emailto={emailTo} disabled={submited} />
        </form>
      )}
    </div>
  );
}
