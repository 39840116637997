import * as yup from "yup";

export const schema = {
  name: {
    name: "name",
    id: "contact-name",
    type: "text",
    component: "InputText",
    label: "Name",
    placeholder: "Enter Name",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  phone: {
    name: "phone",
    id: "contact-phone",
    type: "text",
    component: "InputText",
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  emailFrom: {
    name: "emailFrom",
    id: "contact-emailFrom",
    type: "email",
    label: "Email",
    placeholder: "Enter Email",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().email().required(),
  },
  address: {
    name: "address",
    id: "contact-address",
    type: "text",
    component: "InputText",
    label: "Address",
    placeholder: "Enter Address",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  complaintInvolves: {
    name: "complaintInvolves",
    id: "complaintInvolves",
    type: "text",
    component: "InputTextarea",
    label: "Complaint Involves",
    placeholder: "Type explanation",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  statementOfProblem: {
    name: "statementOfProblem",
    id: "statementOfProblem",
    type: "text",
    component: "InputTextarea",
    label: "Statement of Problem",
    placeholder: "Type explanation",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  desiredResolution: {
    name: "desiredResolution",
    id: "desiredResolution",
    type: "text",
    component: "InputTextarea",
    label: "Desired Resolution",
    placeholder: "Type explanation",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
};

const schemaShape = Object.keys(schema).reduce((result, key) => {
  result[key] = schema[key].validation;
  return result;
}, {});

export const resolver = yup.object().shape(schemaShape);

export default {
  schema,
  resolver,
};
